import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Container, Row, Col, Spinner, Alert, Card } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_GISTIFY_API_URL;

const Gist = () => {
	const [gist, setGist] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchGist = async () => {
		try {
			const response = await fetch(`${API_URL}/fetch-summary?identifier=1853605030`);
			if (!response.ok) throw new Error(`Error fetching gist: ${response.statusText}`);
			const data = await response.json();
			setGist(data);
		} catch (err) {
			console.error('Error fetching gist:', err);
			setError('Failed to fetch the gist. Please try again later.');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchGist();
	}, []);

	const { summary } = gist;

	return (
		<Container fluid>
			<Row className='mb-4'>
				<Col md={6}>
					<h1 className='mb-2'>BasedAI Telegram Gist</h1>
					<p>Gistify summarizes the latest messages from the BasedAI Telegram group.</p>
				</Col>
				<Col md={6}>
					<div className='d-flex justify-content-start justify-content-md-end align-items-center mt-4'>
						<h2 className='text-secondary h5 me-3'>Powered by Gistify</h2>
						<a href='https://www.gistify.xyz/' target='_blank' rel='noopener noreferrer'>
							<img src='/gistify-logo.png' alt='Gistify Logo' width={30} height={30} style={{ marginBottom: '10px', objectFit: 'cover', backgroundColor: '#333', borderRadius: '50%' }} />
						</a>
					</div>
				</Col>
			</Row>

			<Card className='mb-4'>
				<Card.Body>
					{isLoading ? (
						<div className='text-center'>
							<Spinner animation='border' variant='light' />
							<p className='mt-3'>Loading summary...</p>
						</div>
					) : error ? (
						<Alert variant='danger' className='text-center'>
							{error}
						</Alert>
					) : summary && summary.length > 200 ? (
						<Row>
							<Col>
								<div className='markdown-content'>
									<ReactMarkdown>{summary}</ReactMarkdown>
								</div>
							</Col>
						</Row>
					) : (
						<div className='text-center'>
							<p className='text-muted'>No summaries available yet!</p>
						</div>
					)}
				</Card.Body>
			</Card>

			<Row className='text-center mt-5'>
				<Col>
					<Button size='lg' target='_blank' rel='noopener noreferrer' href='https://www.gistify.xyz/' variant='primary'>
						Visit Gistify
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default Gist;
