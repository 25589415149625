import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Collective from './pages/Collective';
import Pools from './pages/Pools';
import Tokens from './pages/Tokens';
import Gist from './pages/Gist';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './App.css';
import './markdown.css';
import { Analytics } from '@vercel/analytics/react';
import Resources from './pages/Resources';
import Faqs from './pages/Faqs';
import Marketplace from './pages/Marketplace';

const App = () => {
	return (
		<Router>
			<Navbar bg='dark' expand='lg' variant='dark'>
				<Container>
					{/* Logo/Brand on the left */}
					<Navbar.Brand as={NavLink} to='/' className='d-flex align-items-center'>
						<img
							src='/social.png'
							alt='Brain Icon'
							width={50}
							height={50}
							className='me-2'
							style={{
								width: '60px',
								height: '60px',
								objectFit: 'cover',
								borderRadius: '50%',
								boxShadow: '0 0 4px 4px #fff',
								backgroundColor: '#333',
							}}
						/>
					</Navbar.Brand>
					<span style={{ borderBottom: '1px solid #777777' }}>Unofficial Tools for BasedAI</span>
					<Navbar.Toggle aria-controls='basic-navbar-nav' className='ms-auto border-0' />

					<Navbar.Collapse id='basic-navbar-nav' className='justify-content-lg-end justify-content-center text-center'>
						<Nav>
							<Nav.Link
								as={NavLink}
								to='/'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								The Collective
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/pools'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								Pools
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/tokens'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								Tokens
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/resources'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								Resources
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/gist'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								Gist
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/nfts/based-pepe'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								NFTs
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to='/faqs'
								className='me-lg-4'
								style={({ isActive }) => ({
									color: isActive ? '#0ee7e2' : 'inherit',
								})}>
								FAQs
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<Container className='py-4 dark-theme'>
				<Routes>
					<Route path='/' element={<Collective />} />
					<Route path='/pools' element={<Pools />} />
					<Route path='/tokens' element={<Tokens />} />
					<Route path='/resources' element={<Resources />} />
					<Route path='/gist' element={<Gist />} />
					<Route path='/faqs' element={<Faqs />} />
					<Route path='/nfts/based-pepe' element={<Marketplace />} />
				</Routes>
			</Container>
			<footer>
				<Container className='text-center py-4'>
					<span className='text-muted'>
						<span className='me-2'>Copyright &copy; 2024 3oh Inc | </span>
						<a href='https://x.com/basedbrains_xyz' target='_blank' rel='noreferrer'>
							@basedbrains_xyz
						</a>
					</span>
				</Container>
			</footer>
			<Analytics />
		</Router>
	);
};

export default App;
