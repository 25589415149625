import React, { useState, useEffect, memo } from 'react';
import { motion } from 'framer-motion';
import { Spinner } from 'react-bootstrap';
import { FaBrain } from 'react-icons/fa';

const TokenComponent = ({ tokenData, fetchTokenHolders, isActivated, hasUnclaimedBalance = false, isTeam = false, onStateChange }) => {
	const tokenMapping = {
		active: { color: '#14a44d' },
		unclaimed: { color: '#14a44d66' },
		inactive: { color: '#555' },
		// team: { color: '#0ee7e2' },
	};

	const [tokenState, setTokenState] = useState('inactive');
	const [imgSrc, setImgSrc] = useState(tokenData?.metadata?.imageUrl || null);
	const [isLoading, setIsLoading] = useState(!!imgSrc);

	// Decide initial token state
	useEffect(() => {
		if (isTeam) {
			setTokenState('team');
		} else if (hasUnclaimedBalance) {
			setTokenState('unclaimed');
		} else if (isActivated) {
			setTokenState('active');
		} else {
			setTokenState('inactive');
		}
	}, [isTeam, hasUnclaimedBalance, isActivated]);

	// Notify parent if needed
	useEffect(() => {
		if (onStateChange) {
			onStateChange(tokenData.tokenId, tokenState);
		}
	}, [tokenState, tokenData.tokenId, onStateChange]);

	// Time out image load after 2s
	useEffect(() => {
		if (!imgSrc) {
			setIsLoading(false);
			return;
		}

		const timer = setTimeout(() => {
			if (isLoading) {
				// Fallback to icon if image took too long
				setImgSrc(null);
				setIsLoading(false);
			}
		}, 2000);

		return () => clearTimeout(timer);
	}, [imgSrc, isLoading]);

	const handleImageLoad = () => {
		setIsLoading(false);
	};

	const handleImageError = () => {
		// fallback to the icon
		setImgSrc(null);
		setIsLoading(false);
	};

	// Safely destructure color with fallback
	const theme = tokenMapping[tokenState] || tokenMapping['inactive'];
	const { color } = theme;

	const pulseVariants = {
		active: {
			scale: [1, 1.25, 1],
			opacity: [1, 1, 1],
			transition: {
				duration: 5,
				repeat: Infinity,
				ease: 'easeInOut',
			},
		},
	};

	// If minted by staking Pepe, you can add a glow
	let boxShadowStyle;
	switch (true) {
		case isTeam:
			boxShadowStyle = '0 0 8px 3px #0ee7e2';
			break;
		case tokenData.mintedByStakingPepe:
			boxShadowStyle = '0 0 8px 3px #14a44d'; // Yellow glow
			break;
		case tokenState === 'active':
			// boxShadowStyle = '0 0 10px 5px #14a44d';
			break;
		case tokenState === 'unclaimed':
			// boxShadowStyle = '0 0 10px 5px rgb(236, 198, 28)'; // Yellow glow
			break;
		default:
			boxShadowStyle = '0 2px 6px rgba(0,0,0,0.15)';
	}

	return (
		<div
			style={{
				width: '4rem',
				cursor: 'pointer',
				borderRadius: '50%',
			}}
			className='text-center'
			onClick={() => fetchTokenHolders(tokenData)}>
			{isLoading && (
				<Spinner
					animation='border'
					variant='secondary'
					size='sm'
					style={{
						width: '3.7rem',
						height: '3.7rem',
						objectFit: 'cover',
						borderRadius: '50%',
					}}
				/>
			)}

			{imgSrc && tokenData?.tokenId !== 169 ? (
				<motion.img
					src={imgSrc}
					alt={`Token #${tokenData.tokenId}`}
					onLoad={handleImageLoad}
					onError={handleImageError}
					className='img-fluid'
					style={{
						width: '4rem',
						height: '4rem',
						objectFit: 'cover',
						borderRadius: '50%',
						display: isLoading ? 'none' : 'block',
						boxShadow: boxShadowStyle,
						backgroundColor: '#333',
					}}
					variants={pulseVariants}
					animate={tokenState}
				/>
			) : (
				<motion.div
					style={{
						fontSize: '40px',
						color: color,
						boxShadow: boxShadowStyle,
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '4rem',
						width: '4rem',
						backgroundColor: '#333',
					}}
					variants={pulseVariants}
					animate={tokenState}>
					<FaBrain />
				</motion.div>
			)}

			<p className='mt-2'>#{tokenData.tokenId}</p>
		</div>
	);
};

export default memo(TokenComponent);
