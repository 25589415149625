import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { shortenAddress } from '../utils';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const Tokens = () => {
	const [tokens, settokens] = useState([]);

	// Fetch and process token data
	useEffect(() => {
		const fetchBrainTokens = async () => {
			try {
				const response = await fetch(`${API_URL}/brain-tokens`);
				if (!response.ok) {
					throw new Error('Error fetching brain tokens');
				}
				const tokensData = await response.json();
				const aggregator = {};

				tokensData.forEach((token) => {
					if (token.isActivated && Array.isArray(token.erc20Holders)) {
						token.erc20Holders.forEach(({ address, balance }) => {
							if (!aggregator[address]) {
								aggregator[address] = { address, total: 0, breakdown: {} };
							}
							aggregator[address].total += balance;
							aggregator[address].breakdown[token.tokenId] = balance;
						});
					}
				});

				const tokensArray = Object.values(aggregator)
					.sort((a, b) => b.total - a.total)
					.slice(0, 100);
				settokens(tokensArray);
			} catch (error) {
				console.error('Error fetching tokens:', error);
			}
		};

		fetchBrainTokens();
	}, []);

	return (
		<>
			<h1 className='fw-bold mb-4'>Tokens (ERC-20)</h1>
			<Card className='shadow-sm border-0'>
				<Card.Body>
					<Card.Title className='fw-bold mb-2 text-center'>Holder's Leaderboard</Card.Title>
					<p className='text-muted mb-4 text-center'>
						Addresses with the largest total ERC-20 balances across all <strong>activated</strong> Brain tokens.
					</p>

					{tokens.length === 0 ? (
						<p>No data or no activated tokens yet.</p>
					) : (
						<Table striped hover responsive>
							<thead>
								<tr>
									<th>#</th>
									<th>Address</th>
									{/* <th>Total Balance</th> */}
									<th>Brain</th>
								</tr>
							</thead>
							<tbody>
								{tokens.map((item, idx) => (
									<tr key={item.address}>
										<td>{idx + 1}</td>
										<td>
											<a href={`https://etherscan.io/address/${item.address}`} target='_blank' rel='noopener noreferrer'>
												{shortenAddress(item.address)}
											</a>
											{item.address === '0xB0974F12C7BA2f1dC31f2C2545B71Ef1998815a4' && (
												<>
													<br />
													<img
														src={'/basedai-logo.jpg'}
														alt='BasedAI'
														style={{
															width: 50,
															height: 50,
															borderRadius: '50%',
															marginLeft: 16,
															objectFit: 'cover',
															// boxShadow: '0 0 5px 0 #0ee7e2',
														}}
													/>
												</>
											)}
										</td>
										{/* <td>
											{item.total.toLocaleString(undefined, {
												maximumFractionDigits: 2,
											})}
										</td> */}
										<td>
											{Object.entries(item.breakdown).map(([tokenId, bal]) => (
												<div key={tokenId} style={{ textWrap: 'nowrap' }}>
													<strong>#{tokenId}:</strong> {bal.toLocaleString()}
												</div>
											))}
											<hr />
											Total:{' '}
											{item.total.toLocaleString(undefined, {
												maximumFractionDigits: 2,
											})}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default Tokens;
