import React from 'react';
import { Accordion, Container, Card } from 'react-bootstrap';

const Faqs = () => {
	return (
		<Container>
			<h1 className='mb-4'>FAQs</h1>
			<Card className='shadow-sm border-0'>
				<Card.Body>
					<Accordion>
						{/* Question 1 */}
						<Accordion.Item eventKey='0'>
							<Accordion.Header>What is an Activated Brain NFT?</Accordion.Header>
							<Accordion.Body>
								An <strong>Activated Brain NFT</strong> is a token that has been initialized and linked to an ERC-20 contract. These NFTs allow holders to claim ERC-20 tokens distributed as part of the BasedAI ecosystem.
								<br />
								<br />
								<strong>Unclaimed Tokens:</strong> If a Brain NFT is activated but shows "unclaimed tokens," it means that some ERC-20 tokens associated with the Brain NFT have not yet been claimed by the holder.
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 2 */}
						<Accordion.Item eventKey='1'>
							<Accordion.Header>What does "Dormant" mean?</Accordion.Header>
							<Accordion.Body>
								A <strong>Dormant Brain NFT</strong> refers to a token that has not been activated. Dormant tokens do not yet have any ERC-20 token distributions, and they lack a connection to an ERC-20 contract. Holders of
								dormant tokens need to activate them to participate in the ecosystem, but they still receive $BASED emissions.
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 3 */}
						<Accordion.Item eventKey='2'>
							<Accordion.Header>What is PepeCoin staking?</Accordion.Header>
							<Accordion.Body>
								<strong>PepeCoin Staking</strong> allows users to stake 100,000 PepeCoin and receive a Brain NFT immediately. The staked PepeCoin is locked for 90 days, after which it can be unstaked by returning and burning
								the Brain NFT. While staked, the Brain NFT accrues $BASED emissions, which can be claimed by the holder.
								<br />
								<br />
								<strong>Key Points:</strong>
								<ul>
									<li>Brain NFTs can be transferred or activated during the 90-day lock period.</li>
									<li>Unstaking PepeCoin requires burning the NFT, which stops emissions for that Brain.</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 4 */}
						{/* <Accordion.Item eventKey='3'>
							<Accordion.Header>What are Validators, Miners, Agents, and Creatures?</Accordion.Header>
							<Accordion.Body>
								<strong>Validators:</strong> Validators ensure the integrity of tasks executed on a Brain. They primarily use CPU resources and validate computations performed by miners.
								<br />
								<br />
								<strong>Miners:</strong> Miners contribute GPU resources to execute computational tasks for a Brain, such as running AI models or ZK-LLMs. They earn $BASED rewards based on their contributions.
								<br />
								<br />
								<strong>Agents:</strong> Agents are collective terms for validators, miners, or memoized wallets who actively participate in supporting a Brain's operations. Each Brain can have up to 256 validators and 1,792
								miners.
								<br />
								<br />
								<strong>Creatures:</strong> Creatures are autonomous AI programs running under Brains. Unlike Agents, they are self-organizing entities that contribute to network innovation by identifying trends, generating
								proposals, and optimizing network intelligence.
							</Accordion.Body>
						</Accordion.Item> */}

						{/* Question 5 */}
						<Accordion.Item eventKey='4'>
							<Accordion.Header>What are ERC-20 Holders?</Accordion.Header>
							<Accordion.Body>
								ERC-20 holders are addresses that hold ERC-20 tokens distributed by activated Brain NFTs. These balances are tied to the ERC-20 contract associated with the activated token. The top holders are displayed on
								the "Tokens" page, along with a breakdown of their balances by Brain token.
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 6 */}
						<Accordion.Item eventKey='5'>
							<Accordion.Header>What are Pools?</Accordion.Header>
							<Accordion.Body>
								<strong>Pools</strong> are collective funding mechanisms where users contribute to mint Brain NFTs. Contributions are tracked, and once a pool reaches the required threshold, the NFT is minted and
								distributed. You can view pooling activity on the "Pools" page.
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 7 */}
						<Accordion.Item eventKey='6'>
							<Accordion.Header>How often is data updated?</Accordion.Header>
							<Accordion.Body>
								All data is refreshed every 60 minutes by scanning the blockchain for changes in the Brain NFT contract. We may reduce this interval in the future to provide more real-time data, but for now we need to keep
								our costs low.
							</Accordion.Body>
						</Accordion.Item>

						{/* Question 8 */}
						<Accordion.Item eventKey='7'>
							<Accordion.Header>How do I claim unclaimed tokens?</Accordion.Header>
							<Accordion.Body>
								Unclaimed tokens can be claimed via the BasedAI platform or a supported DApp. Ensure your Brain NFT is activated and follow the instructions for token claiming. Unclaimed tokens will remain tied to the Brains
								contract address until claimed.
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default Faqs;
