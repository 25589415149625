import React, { memo, useEffect, useState, useMemo, Fragment } from 'react';
import { Card, Badge, Spinner, ProgressBar, InputGroup, Form, Button, Row, Col, Tabs, Tab, Modal, Table } from 'react-bootstrap';
import TokenComponent from '../components/TokenComponent';
import { shortenAddress } from '../utils';
import { FaBrain } from 'react-icons/fa';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const Collective = () => {
	const [brainTokens, setBrainTokens] = useState([]);
	const [holders, setHolders] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [activeTab, setActiveTab] = useState('all');
	const [filters, setFilters] = useState({
		green: false,
		yellow: false,
		grey: false,
		red: false,
	});
	const [modalVisible, setModalVisible] = useState(false);
	const [modalTab, setModalTab] = useState('overview');
	const [selectedTokenData, setSelectedTokenData] = useState(null);
	const [loadingHolders, setLoadingHolders] = useState(false);
	const [tokenHolders, setTokenHolders] = useState([]);

	const brainContractAddress = '0xB0974F12C7BA2f1dC31f2C2545B71Ef1998815a4';
	const basedAiTeamAddress = '0x8caf5b4cb38ba53eecaa45be4ef9cd9d7c0cc057';

	const handleSearchChange = (e) => setSearchTerm(e.target.value);
	const resetSearch = () => {
		setSearchTerm('');
		setFilters({
			active: false,
			dormant: false,
			team: false,
			pepe: false,
		});
	};

	const fetchTokenHolders = (tokenData) => {
		setLoadingHolders(true);
		try {
			setModalVisible(true);
			setSelectedTokenData(tokenData); // Store the full token object

			// Filter holders to find the owner of the specific token
			const ownerData = holders.find((holder) => holder.tokens.some((token) => token.tokenId === tokenData.tokenId));

			// Set the owner's address on the token data for display in the modal
			tokenData.ownerAddress = ownerData?.owner || 'Unknown Owner';

			// Set token holders data
			setTokenHolders(tokenData.erc20Holders || []);
		} catch (error) {
			console.error('Error fetching token holders:', error);
			setTokenHolders([]);
		} finally {
			setLoadingHolders(false);
		}
	};

	const filteredTokens = brainTokens.filter((token) => {
		// 1) Active vs Dormant (OR logic)
		const showActive = filters.active;
		const showDormant = filters.dormant;
		let matchesActivation;

		if (showActive && showDormant) {
			matchesActivation = true;
		} else if (showActive) {
			matchesActivation = token.isActivated;
		} else if (showDormant) {
			matchesActivation = !token.isActivated;
		} else {
			matchesActivation = true;
		}

		// 2) Team vs Pepe (OR logic)
		const showTeam = filters.team;
		const showPepe = filters.pepe;
		let matchesOrigin;

		if (showTeam && showPepe) {
			matchesOrigin = token?.owner?.toLowerCase() === basedAiTeamAddress.toLowerCase() || token.mintedByStakingPepe;
		} else if (showTeam) {
			matchesOrigin = token?.owner?.toLowerCase() === basedAiTeamAddress.toLowerCase();
		} else if (showPepe) {
			matchesOrigin = token.mintedByStakingPepe;
		} else {
			matchesOrigin = true;
		}

		// 3) Final "AND" check
		return matchesActivation && matchesOrigin;
	});

	const renderTokens = useMemo(() => {
		const filteredAndSearchedTokens = filteredTokens.reverse().filter((token) => (searchTerm ? token.tokenId.toString().includes(searchTerm) : true));

		if (filteredAndSearchedTokens.length === 0) {
			return <p>No tokens match the criteria.</p>;
		}

		return (
			<div className='grid-container mt-3'>
				{filteredAndSearchedTokens.map((token) => {
					const hasUnclaimedBalance = token.erc20Holders && token.erc20Holders.some((erc20Holder) => erc20Holder.address.toLowerCase() === brainContractAddress.toLowerCase() && erc20Holder.balance > 0);
					return (
						<div key={token.tokenId} className='grid-item'>
							<MemoizedTokenComponent
								tokenData={token}
								fetchTokenHolders={fetchTokenHolders}
								isActivated={token.isActivated}
								hasUnclaimedBalance={hasUnclaimedBalance}
								isTeam={token?.owner?.toLowerCase() === basedAiTeamAddress.toLowerCase()}
							/>
						</div>
					);
				})}
			</div>
		);
	}, [brainTokens, filters, searchTerm]);

	const renderLeaderboard = () => {
		if (holders.length === 0) {
			return <p>No holders found.</p>;
		}

		return holders.map((holder, index) => (
			<Card key={`holder-${holder.owner}-${index}`} className='mb-4'>
				<Card.Body>
					<div className='d-flex align-items-center justify-content-between'>
						<a href={`https://etherscan.io/address/${holder.owner}`} target='_blank' rel='noopener noreferrer'>
							<h4>{shortenAddress(holder.owner)}</h4>
						</a>
						<Badge bg='primary' text='light' className='me-2' style={{ fontSize: '1rem' }}>
							{holder.count} {holder.count === 1 ? 'Brain' : 'Brains'}
						</Badge>
					</div>

					<div className='grid-container mt-3'>
						{holder.tokens.map((token) => {
							const hasUnclaimedBalance = token.erc20Holders && token.erc20Holders.some((erc20Holder) => erc20Holder.address.toLowerCase() === brainContractAddress.toLowerCase() && erc20Holder.balance > 0);
							return (
								<MemoizedTokenComponent
									tokenData={token}
									fetchTokenHolders={fetchTokenHolders}
									isActivated={token.isActivated}
									hasUnclaimedBalance={hasUnclaimedBalance}
									isTeam={token?.owner?.toLowerCase() === basedAiTeamAddress.toLowerCase()}
								/>
							);
						})}
					</div>
				</Card.Body>
			</Card>
		));
	};

	// Sort so that "Unclaimed" address is always at the top
	const getSortedTokenHolders = () => {
		// Defensive copy
		const sorted = [...tokenHolders];
		sorted.sort((a, b) => {
			const aAddr = a.address.toLowerCase();
			const bAddr = b.address.toLowerCase();
			if (aAddr === brainContractAddress.toLowerCase()) return -1;
			if (bAddr === brainContractAddress.toLowerCase()) return 1;
			// Otherwise sort descending by balance
			return b.balance - a.balance;
		});
		return sorted;
	};

	useEffect(() => {
		const fetchBrainTokens = async () => {
			try {
				const response = await fetch(`${API_URL}/brain-tokens`);
				const data = await response.json();
				setBrainTokens(data);

				const groupedHolders = groupTokensByOwner(data);
				setHolders(groupedHolders);
			} catch (error) {
				console.error('Error fetching brain tokens:', error);
			}
		};

		const groupTokensByOwner = (tokens) => {
			const grouped = {};
			tokens.forEach((token) => {
				if (!grouped[token.owner]) {
					grouped[token.owner] = {
						owner: token.owner,
						count: 0,
						tokens: [],
					};
				}
				grouped[token.owner].count += 1;
				grouped[token.owner].tokens.push(token);
			});
			return Object.values(grouped).sort((a, b) => b.count - a.count); // Sort by most tokens held
		};

		fetchBrainTokens();
	}, []);

	console.log('filteredTokens', filteredTokens);

	return (
		<>
			<h1 className='mb-4'>The Collective</h1>

			<Card className='mb-4'>
				<Card.Body>
					{!brainTokens ? (
						<div className='text-center'>
							<Spinner animation='border' variant='light' />
							<p className='mt-2 mb-0'>Loading Brain NFT data</p>
						</div>
					) : (
						<>
							<div className='row text-center mb-4'>
								<div className='col-4'>
									<h5 className='mb-1'>{1024}</h5>
									<small className='text-muted'>Available</small>
								</div>
								<div className='col-4'>
									<h5 className='mb-1'>{brainTokens.length}</h5>
									<small className='text-muted'>Brains Minted</small>
								</div>
								<div className='col-4'>
									<h5 className='mb-1'>{holders.length}</h5>
									<small className='text-muted'>Unique Holders</small>
								</div>
							</div>

							<div className='mb-0'>
								<ProgressBar
									now={brainTokens.length}
									max={1024}
									variant='success'
									animated
									label={`${Math.floor((brainTokens.length / 1024) * 100).toFixed(2)}%`}
									className='mt-3'
									style={{ height: '2rem', backgroundColor: 'black' }}
								/>
							</div>
						</>
					)}
				</Card.Body>
			</Card>

			<Tabs variant='pills' activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
				<Tab eventKey='all' title={`All (${filteredTokens?.length})`}>
					<Card className='mb-4 shadow-sm'>
						<Card.Body>
							<Card.Title className='fw-bold mb-2 text-center'>Brain Overview</Card.Title>
							<p className='text-muted text-center mb-4'>Brain NFT states and their ERC-20 Token distribution. Updated every 30 minutes.</p>
							<div className='d-md-flex flex-md-wrap gap-md-3 mb-4'>
								{[
									{ key: 'active', title: 'Activated', description: 'Faded indicates unclaimed ERC-20', color: '#14a44d' },
									{ key: 'dormant', title: 'Dormant', description: 'Not activated', color: '#555' },
								].map((filter, index) => (
									<Fragment key={index}>
										{/* Desktop Layout */}
										<Card
											onClick={() =>
												setFilters((prev) => ({
													active: false,
													dormant: false,
													[filter.key]: !prev[filter.key], // Toggle only the clicked filter
													pepe: prev.pepe,
													team: prev.team,
												}))
											}
											className={`flex-grow-1 d-none d-md-flex p-3`}
											style={{
												textAlign: 'center',
												borderRadius: '8px',
												flex: 1,
												justifyContent: 'center',
												alignItems: 'center',
												cursor: 'pointer', // Indicate clickable behavior
												background: filters[filter.key] ? '#75757536' : '#111',
											}}>
											<div
												style={{
													width: 60,
													height: 60,
													flexShrink: 0,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '50%',
													marginBottom: 6,
													// boxShadow: filter.key === 'green' ? '0 0 10px 5px #14a44d' : 0,
													background: filters[filter.key] ? '#111' : '#333',
												}}>
												<FaBrain size={40} color={filter.color} />
											</div>
											<Card.Body className='p-0 pt-2 bg-transparent'>
												<Card.Title className='h6 fw-bold mb-0'>{filter.title}</Card.Title>
												{/* <Card.Text className='text-muted small mb-0'>{filter.description}</Card.Text> */}
											</Card.Body>
										</Card>

										{/* Mobile Layout */}
										<div
											onClick={() =>
												setFilters((prev) => ({
													active: false,
													dormant: false,
													[filter.key]: !prev[filter.key],
													pepe: prev.pepe,
													team: prev.team,
												}))
											}
											className={`d-md-none py-2 px-3 shadow-sm rounded mb-3`}
											style={{
												// border: '1px solid #eaeaea',
												maxWidth: '100%',
												margin: '0 auto',
												cursor: 'pointer', // Indicate clickable behavior
												background: filters[filter.key] ? '#75757536' : '#111',
											}}>
											<div className='d-flex align-items-center'>
												<div
													style={{
														width: 48,
														height: 48,
														flexShrink: 0,
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														borderRadius: '50%',
														marginRight: 12,
														background: filters[filter.key] ? '#111' : '#333',
													}}>
													<FaBrain size={30} color={filter.color} />
												</div>
												<div className='fw-bold'>{filter.title}</div>
												{/* <div className='text-muted small ms-auto'>{filter.description}</div> */}
											</div>
										</div>
									</Fragment>
								))}
							</div>

							<Row className='mb-4'>
								<Col>
									<InputGroup size='lg'>
										<Form.Control type='text' placeholder='Search by ID' value={searchTerm} onChange={handleSearchChange} />
										<Button variant='danger' onClick={resetSearch}>
											Reset
										</Button>
									</InputGroup>
								</Col>
								{/* <Col md={6} className='d-flex align-items-center justify-content-end'>
									<Form.Check inline label='Activated' name='green' type='checkbox' checked={filters.green} onChange={handleFilterChange} />
									<Form.Check inline label='Dormant' name='grey' type='checkbox' checked={filters.grey} onChange={handleFilterChange} />
								</Col> */}
							</Row>
							<br />

							<Row className='mb-2'>
								<Col>
									<div className='d-flex justify-content-end'>
										<div className='d-flex align-items-center me-4'>
											<div
												onClick={() =>
													setFilters((prev) => ({
														...prev,
														pepe: !prev.pepe,
														team: false,
													}))
												}
												style={{
													boxShadow: '0 0 5px 4px #14a44d',
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '1rem',
													width: '1rem',
													backgroundColor: filters.pepe ? '#14a44d' : '#333',
													marginRight: 12,
												}}></div>
											<span>Staked PepeCoin</span>
										</div>

										<div className='d-flex align-items-center'>
											<div
												onClick={() =>
													setFilters((prev) => ({
														...prev,
														team: !prev.team,
														pepe: false,
													}))
												}
												style={{
													boxShadow: '0 0 5px 4px #0ee7e2',
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '1rem',
													width: '1rem',
													backgroundColor: filters.team ? '#0ee7e2' : '#333',
													marginRight: 12,
												}}></div>
											<span>Team Mint</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row className='mb-4'>
								<Col>
									<div className='d-flex justify-content-end text-muted'>
										<p>* Faded indicates unclaimed ERC-20</p>
									</div>
								</Col>
							</Row>
							{renderTokens}
						</Card.Body>
					</Card>
				</Tab>
				<Tab eventKey='leaderboard' title='MasterMinds'>
					{/* <Card className='mb-4 shadow-sm'>
						<Card.Body>
							<Card.Title className='fw-bold mb-2 text-center'>Masterminds</Card.Title>
							<p className='text-muted text-center mb-4'>See the addresses with the most Brain NFTs</p>
						</Card.Body>
					</Card> */}

					{renderLeaderboard()}
				</Tab>
			</Tabs>

			{/* Modal */}
			<Modal
				show={modalVisible}
				// fullscreen={true}
				onHide={() => {
					setModalVisible(false);
					setModalTab('overview'); // reset tab on close
				}}
				size='lg'
				centered>
				<Modal.Header closeButton className='bg-dark text-white'>
					<Modal.Title>{`Brain #${selectedTokenData?.tokenId}`}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Tabs variant='pills' activeKey={modalTab} onSelect={(k) => setModalTab(k)} id='token-modal-tabs' className='mb-3'>
						<Tab eventKey='overview' title='Overview'>
							<Table striped hover responsive>
								<tbody>
									<tr>
										<td style={{ whiteSpace: 'nowrap' }}>Owner</td>
										<td>
											<div className='d-flex justify-content-between align-items-center'>
												<a href={`https://etherscan.io/address/${selectedTokenData?.ownerAddress}`} target='_blank' rel='noopener noreferrer'>
													{shortenAddress(selectedTokenData?.ownerAddress)}
												</a>
												{/* {selectedTokenData?.ownerAddress.toLowerCase() === brainContractAddress.toLowerCase() && (
													<img src={'/basedai-logo.jpg'} alt='BasedAI' style={{ width: 25, height: 25, borderRadius: '50%', marginLeft: 8 }} />
												)} */}
											</div>
										</td>
									</tr>

									<tr>
										<td style={{ whiteSpace: 'nowrap' }}>NFT</td>
										<td>
											<div className='d-flex justify-content-between align-items-center'>
												<a href={`https://opensea.io/assets/ethereum/0xb0974f12c7ba2f1dc31f2c2545b71ef1998815a4/${selectedTokenData?.tokenId}`} target='_blank' rel='noopener noreferrer'>
													View on OpenSea
												</a>
											</div>
										</td>
									</tr>

									{selectedTokenData?.erc20Address && (
										<>
											<tr>
												<td>ERC20 C/A</td>
												<td>
													<a href={`https://etherscan.io/token/${selectedTokenData?.erc20Address}`} target='_blank' rel='noopener noreferrer'>
														{shortenAddress(selectedTokenData?.erc20Address)}
													</a>
												</td>
											</tr>
										</>
									)}
									<tr>
										<td>Minted Via</td>
										<td>
											{selectedTokenData?.mintedByStakingPepe ? (
												<>
													<img
														src='/pepe-logo.jpg'
														alt='Pepe Logo'
														style={{
															width: 30,
															height: 30,
															borderRadius: '50%',
															objectFit: 'cover',
															marginRight: 8,
														}}
													/>
													<span>Staking PepeCoin</span>
												</>
											) : selectedTokenData?.ownerAddress.toLowerCase() === basedAiTeamAddress.toLowerCase() ? (
												<>
													<span>BasedAI Team Mint</span>
												</>
											) : (
												<>{selectedTokenData?.ownerAddress.toLowerCase() === brainContractAddress.toLowerCase() ? <span>Pooled BCRED</span> : <span>Redeemed BCRED</span>}</>
											)}
										</td>
									</tr>
									{selectedTokenData?.mintedByStakingPepe && selectedTokenData?.stakeEndTime ? (
										<tr>
											<td style={{ whiteSpace: 'nowrap' }}>Staking Unlocks</td>
											<td>{moment(selectedTokenData?.stakeEndTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
										</tr>
									) : null}

									{/* <tr>
										<td>Holders</td>
										<td>
											<a href={`https://etherscan.io/token/${selectedTokenData?.erc20Address}#balances`} target='_blank' rel='noopener noreferrer'>
												{selectedTokenData?.erc20Holders?.length || 0} holders
											</a>
										</td>
									</tr> */}

									<tr>
										<td>Image</td>
										<td>
											{selectedTokenData?.metadata?.imageUrl && selectedTokenData?.tokenId !== 169 ? (
												<img src={selectedTokenData?.metadata?.imageUrl} alt='NFT' style={{ width: '100%', maxWidth: '300px', borderRadius: 8 }} />
											) : (
												<div
													style={{
														width: '100%',
														height: 200,
														backgroundColor: '#333',
														borderRadius: 8,
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
													<p className='text-center text-white'>No image available</p>
												</div>
											)}
										</td>
									</tr>

									<tr>
										<td>Name</td>
										<td>{selectedTokenData?.metadata?.name || 'No Name'}</td>
									</tr>
									<tr>
										<td>Ticker</td>
										<td>${selectedTokenData?.metadata?.ticker || 'No Ticker'}</td>
									</tr>
									{selectedTokenData?.tokenId !== 169 && (
										<tr>
											<td colSpan={2}>
												<pre className='wrapped-pre mt-3 bg-light p-2 rounded'>{JSON.stringify(selectedTokenData?.metadata, null, 2)}</pre>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</Tab>

						<Tab eventKey='holders' title='Holders'>
							{loadingHolders ? (
								<div className='text-center'>
									<Spinner animation='border' variant='light' />
									<p className='mt-2 '>Loading holders...</p>
								</div>
							) : (
								<>
									<p>
										{selectedTokenData?.erc20Holders?.length || 0} ERC-20 token holders for Brain #{selectedTokenData?.tokenId} -{' '}
										<a href={`https://etherscan.io/token/${selectedTokenData?.erc20Address}#balances`} target='_blank' rel='noopener noreferrer'>
											Verify on Etherscan
										</a>
									</p>

									{(() => {
										const sortedHolders = getSortedTokenHolders();
										let totalBalance = 0;
										let totalPercentage = 0;

										const holdersMarkup = sortedHolders.map((holder, idx) => {
											const holderPercentage = (holder.balance / 1000000) * 100;
											totalBalance += holder.balance;
											totalPercentage += holderPercentage;

											return (
												<div
													key={idx}
													className={`pt-3 ${holder?.address?.toLowerCase() === brainContractAddress.toLowerCase() ? 'mb-2' : 'pb-2'}`}
													style={
														holder?.address?.toLowerCase() === brainContractAddress.toLowerCase()
															? {
																	padding: '1rem',
																	background: '#333', // Dark background
															  }
															: {}
													}>
													<div className='d-flex justify-content-between align-items-center'>
														<a href={`https://etherscan.io/address/${holder.address}`} target='_blank' rel='noopener noreferrer' className={``}>
															{shortenAddress(holder.address)}
														</a>

														<span>
															{holder.balance.toLocaleString()} tokens ({holderPercentage.toFixed(2)}%)
														</span>
													</div>

													<ProgressBar
														now={holderPercentage.toFixed(2)}
														variant={holder?.address?.toLowerCase() === brainContractAddress.toLowerCase() ? 'danger' : 'success'}
														className='mt-2'
														style={{ height: '0.5rem', backgroundColor: holder?.address?.toLowerCase() === brainContractAddress.toLowerCase() ? '#111' : '#111' }}
													/>
													{holder?.address?.toLowerCase() === brainContractAddress.toLowerCase() && (
														<div className='d-flex justify-content-between align-items-center mt-2'>
															<div>
																<img src={'/basedai-logo.jpg'} alt='BasedAI' style={{ width: 25, height: 25, borderRadius: '50%' }} />
															</div>

															<span>
																<strong>Unclaimed ERC20 Tokens</strong>
															</span>
														</div>
													)}
												</div>
											);
										});

										return (
											<>
												{holdersMarkup}
												{/* Summary Row */}
												<div className='pt-3 mt-4'>
													<div className='d-flex justify-content-between align-items-center'>
														<h5 className='fw-bold '>Distribution</h5>
														<span>
															<strong>{totalPercentage.toFixed(2)}%</strong>
														</span>
													</div>
													<ProgressBar now={totalPercentage} label='100%' variant='success' className='mt-2' style={{ height: '1rem', backgroundColor: 'black' }} />
													<p className=' mt-2'>
														<strong>Total Balance:</strong> {totalBalance.toLocaleString()} tokens
													</p>
													{1000000 - totalBalance > 0 && (
														<p className='text-danger mt-2'>
															<strong>Unclaimed Tokens:</strong> {(1000000 - totalBalance).toLocaleString()} tokens
														</p>
													)}
												</div>
											</>
										);
									})()}
								</>
							)}
						</Tab>

						{/* CHAT TAB */}
						{/* <Tab eventKey='chat' title='Chat'>
							<p>Brain Chat - Chat with fellow token holders - Coming Soon!</p>
						</Tab> */}
					</Tabs>
				</Modal.Body>
			</Modal>
		</>
	);
};

const MemoizedTokenComponent = React.memo(TokenComponent);

export default memo(Collective);
