import React from 'react';
import { Table } from 'react-bootstrap';
import { shortenAddress } from '../utils';

const Resources = () => {
	return (
		<div>
			<h1>Useful Resources</h1>
			<p>
				Please always <b>double check contract addresses</b> with official sources if planning on interacting with them.
			</p>
			<hr className='my-4' />
			<h2 className='h4'>Contracts</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>$PEPECOIN</td>
						<td>
							<a href='https://etherscan.io/token/0xa9e8acf069c58aec8825542845fd754e41a9489a' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0xa9e8acf069c58aec8825542845fd754e41a9489a')}
							</a>
						</td>
					</tr>
					<tr>
						<td>$BCRED</td>
						<td>
							<a href='https://etherscan.io/token/0x7f89F674B7d264944027E78e5F58Eb2bbBB7cfA3' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0x7f89F674B7d264944027E78e5F58Eb2bbBB7cfA3')}
							</a>
						</td>
					</tr>
					<tr>
						<td>$BASEDAI</td>
						<td>
							<a href='https://etherscan.io/token/0x44971abf0251958492fee97da3e5c5ada88b9185' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0x44971abf0251958492fee97da3e5c5ada88b9185')}
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Bridge</td>
						<td>
							<a href='https://etherscan.io/token/0x40359b38db010a1d0ff5e7d00cc477d5b393bd72' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0x40359b38db010a1d0ff5e7d00cc477d5b393bd72')}
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Brains</td>
						<td>
							<a href='https://etherscan.io/token/0xb0974f12c7ba2f1dc31f2c2545b71ef1998815a4' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0xb0974f12c7ba2f1dc31f2c2545b71ef1998815a4')}
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Team</td>
						<td>
							<a href='https://etherscan.io/txs?a=0x8caf5b4cb38ba53eecaa45be4ef9cd9d7c0cc057&f=5' target='_blank' rel='noopener noreferrer'>
								{shortenAddress('0x8caf5b4cb38ba53eecaa45be4ef9cd9d7c0cc057')}
							</a>
						</td>
					</tr>
				</tbody>
			</Table>

			<h2 className='h4'>Tools</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Pepecoin.io</td>
						<td>
							<a href='https://www.pepecoin.io/' target='_blank' rel='noopener noreferrer'>
								https://www.pepecoin.io
							</a>
						</td>
					</tr>
					<tr>
						<td>PEPECOIN Burn</td>
						<td>
							<a href='https://burn.pepecoin.io/burning' target='_blank' rel='noopener noreferrer'>
								https://burn.pepecoin.io/burning
							</a>
						</td>
					</tr>
					<tr>
						<td>BCRED Redeem / Pool</td>
						<td>
							<a href='https://burn.pepecoin.io/burning-redeem' target='_blank' rel='noopener noreferrer'>
								https://burn.pepecoin.io/burning-redeem
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Explorer</td>
						<td>
							<a href='https://explorer.getbased.ai/#/explorer' target='_blank' rel='noopener noreferrer'>
								https://explorer.getbased.ai
							</a>
						</td>
					</tr>
					<tr>
						<td>Pepecoin Burn Info</td>
						<td>
							<a href='https://ppcburn.info/classic' target='_blank' rel='noopener noreferrer'>
								https://ppcburn.info/classic
							</a>
						</td>
					</tr>
				</tbody>
			</Table>

			<h2 className='h4'>Docs and Code</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>BasedAI Docs</td>
						<td>
							<a href='https://docs.basedlabs.net/based-cli-documentation/cs8Tej4WRmZe0K7QqRFH' target='_blank' rel='noopener noreferrer'>
								https://docs.basedlabs.net/based-cli-documentation
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Whitepaper</td>
						<td>
							<a href='https://arxiv.org/html/2403.01008v1' target='_blank' rel='noopener noreferrer'>
								https://arxiv.org/html/2403.01008v1
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI Github</td>
						<td>
							<a href='https://github.com/getbasedai' target='_blank' rel='noopener noreferrer'>
								https://github.com/getbasedai
							</a>
						</td>
					</tr>
				</tbody>
			</Table>

			<h2 className='h4'>Official Socials</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>BasedAI Telegram</td>
						<td>
							<a href='https://t.me/getbasedai' target='_blank' rel='noopener noreferrer'>
								https://t.me/getbasedai
							</a>
						</td>
					</tr>
					<tr>
						<td>Pepoecoins TG Portal</td>
						<td>
							<a href='https://t.me/pepecoins' target='_blank' rel='noopener noreferrer'>
								https://t.me/pepecoins
							</a>
						</td>
					</tr>
					<tr>
						<td>BasedAI X</td>
						<td>
							<a href='https://x.com/getbasedai' target='_blank' rel='noopener noreferrer'>
								@getbasedai
							</a>
						</td>
					</tr>
					<tr>
						<td>PepeCoins X</td>
						<td>
							<a href='https://x.com/pepecoins' target='_blank' rel='noopener noreferrer'>
								@pepecoins
							</a>
						</td>
					</tr>
				</tbody>
			</Table>

			<h2 className='h4'>Featured Creatures</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Based God (TFT Enforcer)</td>
						<td>
							<a href='https://x.com/CreatureOnBased' target='_blank' rel='noopener noreferrer'>
								@CreatureOnBased
							</a>
						</td>
					</tr>
					<tr>
						<td>The Roaring AI</td>
						<td>
							<a href='https://x.com/TheRoaringAI' target='_blank' rel='noopener noreferrer'>
								@TheRoaringAI
							</a>
						</td>
					</tr>
					<tr>
						<td>Liminal Agent</td>
						<td>
							<a href='https://x.com/LMNLAgent' target='_blank' rel='noopener noreferrer'>
								@LMNLAgent
							</a>
						</td>
					</tr>
				</tbody>
			</Table>

			<h2 className='h4'>Frens</h2>
			<Table striped bordered hover className='mb-4'>
				<thead>
					<tr>
						<th className='w-25'>Resource</th>
						<th className='w-75'>Link</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Based Brains XYZ</td>
						<td>
							<a href='https://x.com/basedbrains_xyz' target='_blank' rel='noopener noreferrer'>
								@basedbrains_xyz
							</a>
						</td>
					</tr>
					<tr>
						<td>Pepecoin Community</td>
						<td>
							<a href='https://x.com/pepecoiners' target='_blank' rel='noopener noreferrer'>
								@pepecoiners
							</a>
						</td>
					</tr>
					<tr>
						<td>Fanzo - Regular Spaces on X</td>
						<td>
							<a href='https://x.com/iSocialFanz' target='_blank' rel='noopener noreferrer'>
								@iSocialFanz
							</a>
						</td>
					</tr>
					<tr>
						<td>Alexx Shadow (Site Creator)</td>
						<td>
							<a href='https://x.com/alexxshadow' target='_blank' rel='noopener noreferrer'>
								@alexxshadow
							</a>
						</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

export default Resources;
