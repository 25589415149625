import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form, Pagination, InputGroup, Table } from 'react-bootstrap';
import nfts from '../data.json';
import { shortenAddress } from '../utils';

const Marketplace = () => {
	const [selectedNft, setSelectedNft] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(50);

	const handleImageClick = (nft) => {
		setSelectedNft(nft);
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
		setSelectedNft(null);
	};

	// Filter NFTs by ID
	const filteredNfts = nfts.filter((nft) => {
		const id = nft.name.split('#')[1];
		return id.includes(searchTerm);
	});

	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredNfts.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(filteredNfts.length / itemsPerPage);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
	const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

	return (
		<Container fluid>
			<h1 className='mb-2'>Based Pepe</h1>
			<p className='mb-4'>500 Based Pepes, launched by @alexxshadow as the first pfp collections on the BasedAI L1.</p>

			<Table striped hover responsive>
				<tbody>
					<tr>
						<td className='w-25' style={{ whiteSpace: 'nowrap' }}>
							Contract Address
						</td>
						<td>
							<div className='d-flex justify-content-between align-items-center'>{shortenAddress('0xD819b90F7a7f8E85639671D2951285573bbf8771')}</div>
						</td>
					</tr>

					<tr>
						<td style={{ whiteSpace: 'nowrap' }}>Total minted</td>
						<td>
							<div className='d-flex justify-content-between align-items-center'>500 / 500</div>
						</td>
					</tr>
				</tbody>
			</Table>

			<Card className='mb-4 shadow-sm'>
				<Card.Body>
					{/* Search Bar */}

					<Row className='mb-4'>
						<Col>
							<InputGroup size='lg'>
								<Form.Control
									type='text'
									placeholder='Search by ID'
									value={searchTerm}
									onChange={(e) => {
										setSearchTerm(e.target.value);
										setCurrentPage(1); // Reset to first page when searching
									}}
								/>
								<Button variant='danger' onClick={() => setSearchTerm('')}>
									Reset
								</Button>
							</InputGroup>
						</Col>
						{/* <Col md={6} className='d-flex align-items-center justify-content-end'>
									<Form.Check inline label='Activated' name='green' type='checkbox' checked={filters.green} onChange={handleFilterChange} />
									<Form.Check inline label='Dormant' name='grey' type='checkbox' checked={filters.grey} onChange={handleFilterChange} />
								</Col> */}
					</Row>
					<br />

					{/* NFT Grid */}
					<Row xs={1} md={2} lg={3} xl={4} className='g-4'>
						{currentItems.map((nft, index) => (
							<Col key={index}>
								<Card className='h-100 shadow-sm border-0'>
									<Card.Img
										variant='top'
										src={nft.image}
										alt={nft.name}
										onClick={() => handleImageClick(nft)}
										style={{
											objectFit: 'cover',
											minHeight: '250px',
											aspectRatio: '1 / 1',
											borderTopLeftRadius: '0.25rem',
											borderTopRightRadius: '0.25rem',
											cursor: 'pointer',
										}}
									/>
									<Card.Body className='d-flex flex-column'>
										<Card.Title className='mb-3'>{nft.name}</Card.Title>
										<Card.Text className='text-muted small flex-grow-1'>Owner data coming soon</Card.Text>
										<Button variant='primary' href={nft.animationUrl} target='_blank' rel='noopener noreferrer'>
											View Glitch Version
										</Button>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>

					{/* Pagination Controls */}
					<div className='d-flex justify-content-center mt-4'>
						<Pagination>
							<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
							<Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />

							{/* Always show first page */}
							<Pagination.Item active={1 === currentPage} onClick={() => paginate(1)}>
								1
							</Pagination.Item>

							{/* Show ellipsis if needed */}
							{currentPage > 4 && <Pagination.Ellipsis disabled />}

							{/* Dynamic page numbers */}
							{Array.from({ length: totalPages }, (_, i) => i + 1)
								.filter((page) => page > 1 && page < totalPages)
								.slice(Math.max(0, currentPage - 3), currentPage + 2)
								.map((page) => (
									<Pagination.Item key={page} active={page === currentPage} onClick={() => paginate(page)}>
										{page}
									</Pagination.Item>
								))}

							{/* Show ellipsis if needed */}
							{currentPage < totalPages - 3 && <Pagination.Ellipsis disabled />}

							{/* Always show last page */}
							{totalPages > 1 && (
								<Pagination.Item active={totalPages === currentPage} onClick={() => paginate(totalPages)}>
									{totalPages}
								</Pagination.Item>
							)}

							<Pagination.Next onClick={nextPage} disabled={currentPage === totalPages} />
							<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
						</Pagination>
					</div>
				</Card.Body>
			</Card>

			{/* Image Modal */}
			<Modal show={showModal} onHide={handleClose} size='xl' centered>
				<Modal.Header closeButton>
					<Modal.Title>{selectedNft?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body className='text-center'>
					{selectedNft && (
						<img
							src={selectedNft.image}
							alt={selectedNft.name}
							style={{
								maxWidth: '100%',
								height: 'auto',
								borderRadius: '0.25rem',
							}}
						/>
					)}
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default Marketplace;
