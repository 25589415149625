import React, { useState, useEffect, memo } from 'react';
import { Card, Spinner, Accordion, ProgressBar, Table } from 'react-bootstrap';
import { shortenAddress } from '../utils';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const Pools = () => {
	const [batches, setBatches] = useState([]);
	const [poolContributors, setPoolContributors] = useState([]);
	const [selectedBatchIndex, setSelectedBatchIndex] = useState(null);
	const [loading, setLoading] = useState(false);
	const [currentPool, setCurrentPool] = useState(null);
	const [progressValue, setProgressValue] = useState(0); // For progress bar animation
	const [loadingContributors, setLoadingContributors] = useState(false);
	const [accordionLoadingState, setAccordionLoadingState] = useState({}); // Loader state for each accordion item

	const fetchPoolContributors = async (batchIndex) => {
		setLoadingContributors(true);
		setAccordionLoadingState((prevState) => ({ ...prevState, [batchIndex]: true }));
		setSelectedBatchIndex(batchIndex);
		try {
			const response = await fetch(`${API_URL}/pools/contributors/${batchIndex}`);
			if (!response.ok) throw new Error(`Error fetching contributors: ${await response.text()}`);
			const data = await response.json();
			setPoolContributors(data.contributors);
		} catch (error) {
			setPoolContributors([]);
			console.error(`Error fetching contributors for batch ${batchIndex}:`, error);
		} finally {
			setLoadingContributors(false);
			setAccordionLoadingState((prevState) => ({ ...prevState, [batchIndex]: false }));
		}
	};

	const fetchLatestPool = async (batchIndex) => {
		setLoading(true);
		try {
			const response = await fetch(`${API_URL}/pools/contributors/${batchIndex}`);
			if (!response.ok) throw new Error(`Error fetching contributors: ${await response.text()}`);
			const data = await response.json();

			setCurrentPool(data);

			// Calculate total contribution and animate progress bar
			const totalContributed = data.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0);

			setProgressValue(0); // Start at 0
			setTimeout(() => {
				setProgressValue(totalContributed); // Update to actual value
			}, 100); // Allow small delay to trigger animation
		} catch (error) {
			setCurrentPool(null);
			console.error('Error fetching current pool:', error);
		} finally {
			setLoading(false);
		}
	};

	const fetchBatches = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${API_URL}/batches`);
			if (!response.ok) throw new Error(`Error fetching batches: ${await response.text()}`);
			const data = await response.json();
			setBatches(data.sort((a, b) => a.batchIndex - b.batchIndex));
			const latestBatchIndex = data[data.length - 1]?.batchIndex;
			if (latestBatchIndex != null) {
				fetchLatestPool(latestBatchIndex);
				fetchPoolContributors(latestBatchIndex);
			}
		} catch (error) {
			console.error('Error fetching initial batches:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBatches();
	}, []);

	const filteredBatches = batches.filter((batch) => batch.batchIndex.toString().includes(''));

	return (
		<>
			<h1 className='fw-bold mb-4'>Pools</h1>
			<Card className='mb-4'>
				<Card.Body>
					{currentPool ? (
						<>
							<div className='row text-center mb-4'>
								<div className='col-4'>
									<h5 className='mb-1'>#{currentPool?.batchIndex}</h5>
									<small className='text-muted'>Current Batch</small>
								</div>
								<div className='col-4'>
									<h5 className='mb-1'>{(1000 - currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0)).toFixed(2)}</h5>
									<small className='text-muted'>BCRED to Fill</small>
								</div>
								<div className='col-4'>
									<h5 className='mb-1'>{currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0) === 1000 ? 'Full' : 'Pooling'}</h5>
									<small className='text-muted'>Status</small>
								</div>
							</div>

							<div className='mb-0'>
								<ProgressBar now={progressValue} max={1000} variant='success' animated label={`${Math.floor((progressValue / 1000) * 100)}%`} className='mt-3' style={{ height: '2rem', backgroundColor: 'black' }} />
							</div>
						</>
					) : (
						<div className='text-center'>
							<Spinner animation='border' variant='light' />
							<p className='mt-2 mb-0'>Loading current pool data...</p>
						</div>
					)}
				</Card.Body>
			</Card>

			<Card className='mb-4'>
				<Card.Body>
					{loading ? (
						<div className='text-center'>
							<Spinner animation='border' variant='light' />
							<p className='mt-2 mb-0'>Loading all pool data...</p>
						</div>
					) : (
						<>
							<Card.Title className='fw-bold mb-2 text-center'>Pooling Data</Card.Title>
							<p className='text-muted mb-4 text-center'>Current and historical BCRED pooling data. including the top contributors for each pool. Refreshed every 60 minutes.</p>

							<Accordion flush defaultActiveKey={filteredBatches[filteredBatches.length - 1]?.batchIndex?.toString()}>
								{filteredBatches.reverse().map((batch, index) => (
									<Accordion.Item key={batch.batchIndex} eventKey={batch.batchIndex.toString()}>
										<Accordion.Header
											onClick={() => {
												if (selectedBatchIndex !== batch.batchIndex) {
													fetchPoolContributors(batch.batchIndex);
												}
											}}>
											<div className='d-flex justify-content-between align-items-center w-100'>
												<span>
													Pool #{batch.batchIndex} {index === 0 && '(Current)'}
												</span>
											</div>
										</Accordion.Header>
										<Accordion.Body className='p-0'>
											{accordionLoadingState[batch.batchIndex] ? (
												<div className='text-center mt-4'>
													<Spinner animation='border' variant='light' />
													<p className='mt-2 text-white'>Loading contributors...</p>
												</div>
											) : (
												selectedBatchIndex === batch.batchIndex && (
													<Table striped hover responsive border='0' className='mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Address</th>
																<th>Total Contributed</th>
															</tr>
														</thead>
														<tbody>
															{poolContributors
																.sort((a, b) => b.totalContributed - a.totalContributed)
																.map((contributor, idx) => (
																	<tr key={idx}>
																		<td>{idx + 1}</td>
																		<td>
																			<a href={`https://etherscan.io/address/${contributor.address}`} target='_blank' rel='noopener noreferrer'>
																				{shortenAddress(contributor.address)}
																			</a>
																		</td>
																		<td>{contributor.totalContributed.toLocaleString()} BCRED</td>
																	</tr>
																))}
															<tr>
																<td colSpan='2' className='text-center'></td>
																<td colSpan='1' className='text-left'>
																	<strong>Total:</strong> {poolContributors.reduce((acc, curr) => acc + curr.totalContributed, 0).toLocaleString()} BCRED
																</td>
															</tr>
														</tbody>
													</Table>
												)
											)}
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default memo(Pools);
